<template>
    <keep-alive>
        <router-view />
    </keep-alive>
</template>
<script>
export default {
    name: 'App',
    created() {
        var that = this

        if (sessionStorage.getItem("store")) {
            let store = sessionStorage.getItem("store")
            let dataStore = JSON.parse(store)
            dataStore.ascription = this.$store.state.ascription

            console.log(Object.hasOwnProperty.call(dataStore.user.dataVal, 'power_open'), 'hasOwnPropertyhasOwnPropertyhasOwnProperty');

            if (Object.hasOwnProperty.call(dataStore.user.dataVal, 'power_open')) {
                console.log('已经有power_open');
                dataStore.user.dataVal.power_open = dataStore.user.dataVal.power_open
            } else {
                console.log('没有power_open111111111');
                dataStore.user.dataVal.power_open = 999
            }
            console.log(dataStore, "dataStore.ascriptiondataStore.ascription");
            sessionStorage.setItem("store", JSON.stringify(dataStore))
        }
        console.log('--');
        //在页面加载时读取sessionStorage里的状态信息
        if (sessionStorage.getItem("store")) {
            this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem("store"))))
        }
 
        //在页面刷新时将vuex里的信息保存到sessionStorage里
        window.addEventListener("beforeunload", () => {
            if (that.$store.state.login.islogin) {
                sessionStorage.setItem("store", JSON.stringify(this.$store.state))
            }
        })
    }
}
</script>